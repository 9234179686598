.bdo-tooltip {
  color: var(--red) !important;
}

.ant-tooltip-inner {
  background-color: #ffffff !important;
  color: var(--paleDarkCharcoal) !important;
  padding: var(--XS) var(--S) !important;
  box-shadow: 1px 1px 1px 1px #3333331f !important;
  
}

.ant-tooltip-arrow::before, .ant-tooltip-arrow::after {
 color: #ffffff !important;
 background-color: #ffffff !important;
 box-shadow: 2px 2px 2px 2px #3333331f !important;

}

.left-margin {
  margin-left: 10px;
}

.align-right {
  margin-left: auto;
}