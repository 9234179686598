.ant-btn {
  display: inline-flex;
  align-items: center;
  padding: 15px;
  font-weight: 700;
  box-shadow: none !important;
}

/* Change ant default button into secondary */

button.ant-btn-default {
  background-color: var(--paleCharcoalDark);
}


button.ant-btn-default:hover:not(.ant-btn-circle) {
  color: var(--charcoal) !important;
  background-color: var(--charcoalLight) !important;
  border-color: var(--charcoalLight) !important;
}

button.ant-btn:disabled {
  color: var(--charcoalLight) !important;
  cursor: not-allowed;
}