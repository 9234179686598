div.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--M);
}

/* Table status labels */
.pending button, .confirmed button, .declined button, .consult button {
  color: #ffffff !important;
}

.pending button:hover, .confirmed button:hover, .declined button:hover, .consult button:hover {
  cursor: default;
  color: #ffffff !important;
}

.pending button, .pending button:hover{
  background-color: var(--charcoal);
  border-color: var(--charcoal) !important;
}

.confirmed button, .confirmed button:hover {
  background-color: var(--emerald);
  border-color: var(--emerald) !important;
}

.declined button,.declined button:hover {
  background-color: var(--burgundy);
  border-color: var(--burgundy) !important;
}

.consult button, .consult button:hover {
  background-color: var(--gold);
  border-color: var(--gold) !important;
}
