.engagements-list {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 10px;
    position: relative;
  }
   
  .engagements-list .ant-list-header {
    position: sticky;
    top: 0; 
    background-color: #fff;
    z-index: 1;
    padding: 8px;
    font-weight: bold;
  }
   
  .engagement-item {
    padding: 10px;
    transition: background-color 0.3s ease;
  }
   
  .engagement-item:hover {
    background-color: #ffe6e6;
    cursor: pointer;
  }
   
  .engagement-radio {
    margin-right: 10px;
  }
   
  .selected .engagement-radio {
    font-weight: bold;
  }
   
  .engagement-radio div {
    margin: 2px 0;
  }

  .bdoModal {
    width: 570px !important;
}
p.new-engagement-hint {
    font-style: italic;
    font-size: var(--S);
    padding-top: var(--XS);
  }