/* antd style overrides */
.bdoModal {
  width: 450px !important;
  padding-left: var(--XL);
  padding-right: var(--XL);
}

.bdoModal .full-width-divider {
  margin-left: calc(var(--XL) * -1);
  width: calc(100% + var(--XL) + var(--XL));
  margin-bottom: var(--XL) !important;
}

/* General Field styles */

.bdoModal .ant-select{
  width: 100%;
}

.bdoModal .ant-select {
  margin-top: 0 !important;
}

.bdoModal .ant-select-selector {
  border: 1px solid var(--paleCharcoal);
  line-height: var(--XXL);
  transition: all 0.3s;
}

.bdoModal .ant-select-selector:focus-within, .bdoModal div .ant-select-selector:hover {
  border: 1px solid var(--redDark);
  box-shadow: 0 0 0 2px rgba(255, 25, 25, 0.1);
  width: 100%;
}

.bdoModal input{
  border: none;
}

.bdoModal .ant-input-group-addon {
  background-color: transparent;
  border: none;
}

.searchEngagementInput input:focus, .searchEngagementInput input:hover {
  border-color: transparent;
  box-shadow: none;
}

.searchEngagementInput input:disabled {
  background-color: transparent;
}

.searchEngagementPopup {
  box-shadow: 3px 3px 3px 3px #3333331f !important;
}

.searchEngagementPopup .ant-select-item-option {
  align-items: center;
  padding-top: var(--S);
  padding-bottom: var(--S);
  padding-left: var(--XXL);
}

.bdoModal .ant-select-show-arrow {
  display: flex;
  align-items: center;
}

.bdoModal .ant-select-show-arrow .ant-select-arrow {
  top: unset !important;
}
.bdoModal .anticon.anticon-down.ant-select-suffix svg{
  fill: var(--red);
}
p.fieldLabel {
  align-items: center;
  font-size: var(--S);
  color: var(--charcoal);
  margin-bottom: var(--XXS);
  margin-top: var(--XS);
  font-weight: 600;
  padding-bottom: var(--XXS);
}

span.invite-link {
  color:var(--red);
  font-weight: bold;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; /* Adjust as needed */
}

.ant-modal-header {
  padding-bottom: var(--XS);
}

.bdoModal .ant-modal-title {
  padding-bottom: var(--XS);
  padding-left: var(--XL);
  font-size: var(--L);
  font-weight: 600;
  border-bottom: var(--charcoal) 1px solid;
  margin-left: calc(var(--XL) * -1);
  width: calc(100% + var(--XL) + var(--XL));

}

.ant-modal-content .ant-modal-close {
  right: var(--XL);
}

/* Footer styles */

/* Override antd defaults */
.bdoModal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--XL);
  gap: var(--S);
}

.bdoModal .ant-modal-footer button {
  min-width: 8rem;
}