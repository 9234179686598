/* Table styles */
th {
  background-color: #ffffff !important;
}

tr.ant-table-row td.ant-table-cell {
  border-color: var(--paleCharcoalDark) !important;
}

th.ant-table-cell::before{
 background-color: #ffffff !important;
}

/* Table filter icon styles */
.anticon-filter, .anticon-caret-up, .anticon-caret-down {
  font-size: var(--XS);
}

.anticon-caret-up:not(.active), .anticon-caret-down:not(.active){
  color: var(--charcoal);
}

.ant-table-filter-trigger:not(.active){
  color: var(--charcoal);
}

/* Table filter styles */
.ant-table-filter-dropdown {
  box-shadow: 3px 3px 3px 3px #3333331f !important;
  min-width: 250px;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-search {
  border-color: var(--paleCharcoalDark) !important;
  padding-left: var(--S) !important;
  padding-right: var(--S) !important;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-search input {
  line-height: var(--S) !important;
  
}

.ant-table-filter-dropdown-search-input .ant-input-prefix {
  margin-left: var(--XXS);
}

.ant-table-filter-dropdown-btns {
  padding: var(--XXS) var(--M) !important;
  border-color: var(--paleCharcoalDark) !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  padding: 0 var(--XS);
}

.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: var(--XXS) var(--XS);
}

/* Table button styles */

button {
  justify-content: center;
}
.actions button {
  border: none;
  box-shadow: none;
  color: var(--red);
  font-size: var(--XL);
  background-color: transparent !important;
}

.actions button:hover {
  color: var(--red) !important;
  background-color: transparent !important;
}


/* Table action menu styles */
.actionMenu {
  min-width: var(--jumbo5);
  box-shadow: 2px 2px 2px 2px #3333331f !important;
}
.actionMenu li {
  color: var(--red) !important;
  text-align: center;
  line-height: var(--L);
  padding: var(--XS) var(--S);
}
.actionMenu li:not(:first-child) {
  border-top: 1px solid var(--paleCharcoalDark) !important;
}

/* Pagination overrides */
.ant-pagination-item-active {
  color: #ffffff !important;
  background-color: var(--red) !important;
}

.ant-pagination-options .ant-pagination-options-size-changer {
  display: flex;
  align-items: center;
  width: calc(var(--XXL) * 4);
}

.ant-pagination-options-size-changer span.ant-select-arrow {
  top: unset !important;
}

.ant-pagination-options-size-changer .ant-select-dropdown {
  box-shadow: 3px 3px 3px 3px #3333331f !important;
}

.ant-pagination-options-size-changer .ant-select-item-option-content{
  display: inline-flex;
  align-items: center;
  padding-left: var(--M);
}