/* Global Styles */

.App {
  min-height: 100vh;
}

main {
  flex: 1 0;
  width: 100%;
  max-width: calc(1200px + calc(var(--XXL)*2));
  margin: 0 auto;
  padding: 10px var(--XXL);
}

.ant-alert-warning {
  background-color: #FBF2E6;
  border: none;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 8px;
  color: #804900;
  font-size: NaNpx;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 8px 12px;
  color: #804900;
}
.ant-alert-warning .ant-alert-icon {
  color: #804900;
  font-size: 20px;
  margin-top: 2px;
}

.general-error-message {
  font-style: italic;
  line-height: var(--S);
  font-size: var(--S);
  padding-top: var(--XXS);
  color: var(--burgundy);
  transition: color 0.3s ease-in-out;
}