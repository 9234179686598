.bdo-alert-container .ant-alert {
    width: 100%;
    padding: var(--L);
    display: flex;
    gap: var(--L);
    min-height: 80px;
    align-items: center;
    margin-bottom: var(--L);
    border: none;
  }
  
  .bdo-alert-container .ant-alert svg {
    height: var(--XL);
    width: var(--XL);
  }
  
  .ant-alert-message {
    font-size: var(--M);
    font-weight: bold;
  }
  
  .validation-message-icon {
    font-size: var(--XL);
  }
  
  .validation-message-text {
    font-weight: normal !important;
  }
  
  .bdo-alert-container.success .ant-alert-success {
    background-color: var(--validation-success) !important;
  }
  
  .bdo-alert-container.success .ant-alert-success svg {
    fill: var(--greenDark);
  }
  
  .bdo-alert-container.error .ant-alert-error {
    background-color: var(--validation-error) !important;
  }
  
  .bdo-alert-container.error .ant-alert-error svg {
    fill: var(--redDark);
  }
  
  
  .bdo-alert-container.info .ant-alert-info {
    background-color: var(--emeraldLight) !important;
  }
  
  .bdo-alert-container.info .ant-alert-info svg {
    fill: var(--emeraldDark);
  }
  