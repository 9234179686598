.masthead {
  padding: 0 var(--XXL);
  background: var(--paleCharcoalLight);
  box-shadow: 0 3px 6px 0px #3333331f;
  justify-self: flex-start;
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.branding {
  align-items: center;
  height: var(--jumbo3);
  margin-bottom: -5px !important;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  align-items: center;
}

.branding svg {
  height: var(--XXXL);
}

.masthead > h3{
  margin-bottom: 0;
}

.masthead .loginButtonContainer {
  margin-left: auto;
}

.bdoBlue {
  fill: #27348b;
}

.bdoRed {
  fill: #ed1a3b;
}