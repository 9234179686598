.bdo-file-upload-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--red);
  padding: var(--XS);
  background-color: var(--paleCharcoalLight);
}

.bdo-file-upload-container p {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.bdo-upload-icon {
  font-size: 48px;
  color: var(--red)
}

.ant-upload-list-item {
  margin-top: 0 !important;
}

/* .bdo-file-upload-container .ant-upload-drag{
  
} */

.ant-upload-wrapper .ant-upload-drag {
border:none;
background: none;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0;
}