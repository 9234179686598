/* ErrorPage.jsx styles */

.errorPageContainer {
    max-width: 600px;
    margin: auto;
    padding: 40px;
  }
  
  .errorPageContainer .iconContainer {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .errorPageContainer h1 {
    border: 0;
    text-align: center;
    display: block;
  }
  
  .errorPageContainer p.infoText {
    text-align: center;
  }
  
  .errorPageContainer p.infoText a {
    color: var(--red);
  }
  
  .errorPageContainer .accordionItem {
    width: 500px;
  }
  
  .errorPageContainer .accordionBody {
    font-size: 0.8rem;
  }
  
  