.bdoContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  gap: var(--XXS);
}


.bdoContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}