footer {
  background: var(--paleCharcoalLight);
  box-shadow: 0 -3px 6px 0px #3333331f;
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

footer div {
  height: var(--jumbo3);
  margin-top: auto;
  margin-bottom: -5px !important;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer div svg {
  height: var(--XXXL);
  justify-self: flex-end;
}
.logo-labs-ellipse {
  fill: #f68c9d;
}
.logo-labs-path {
  fill: #ed1a3b;
}

.text-container {
  flex: 1; /* The text container takes up available space */
  padding: 10px; /* Add padding as needed */
}
